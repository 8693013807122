<template>
  <div class="page" id="page">
    <heade :active="6"></heade>
    <div style="height: 64px"></div>
    <div class="page-bg" :style="{ height: bannerHeight + 'px' }">
      <img
        src="../assets/images/aboutMine/bg.png"
        alt=""
        :style="{ height: bannerHeight + 'px' }"
      />
      <div class="page-text">
        <div class="china">关于康米斯</div>
        <div class="english">About Comis</div>
      </div>
      <!-- 公司简介 -->
    </div>
    <div class="company">
      <div class="company-content">
        <div class="name-title" style="padding: 96px 0 48px 0">
          <div class="name-name">公司简介</div>
          <div class="name-name-eng">Company Profile</div>
        </div>
        <div class="text">
          浙江康米斯信息技术有限公司是一家以IOT设备连接和云服务平台为基础，围绕物联网、车联网等多种行业场景，依托云计算、大数据、人工智能、物联网等信息技术，提供北斗+物联网位置服务的科技型企业。
        </div>
        <div class="text">
          公司提供“北斗智能设备+云平台+行业数字化解决方案”等多种产品组合，为用户多元需求场景提供差异化的解决方案。
        </div>
        <div class="text">
          自主研发的核心产品“智位星物联”云平台及大数据应用，创新融合实现基于北斗+物联网位置服务的多场景需求。助力企业降本增效，提高行业竞争优势，更好的服务行业用户，赋能行业发展。
        </div>
      </div>
    </div>
    <!-- 企业文化 -->
    <div class="education">
      <div class="education-content">
        <div class="name-title" style="padding: 106px 0 48px 0">
          <div class="name-name">企业文化</div>
          <div class="name-name-eng">corporate culture</div>
        </div>
        <div class="education-text">
          <div class="mission">
            <div class="title">使命</div>
            <div class="desc">让位置更有价值</div>
          </div>
          <div class="price">
            <div class="title">价值观</div>
            <div class="desc">
              <span>诚信</span>
              <span>开放</span>
              <span>双赢</span>
            </div>
          </div>
          <div class="future">
            <div class="title">愿景</div>
            <div class="desc">
              <span>专注位置服务</span>
              <span>赋能行业发展</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 团队能力 -->
    <div class="team">
      <div class="team-content">
        <div class="name-title" style="padding: 96px 0 64px 0">
          <div class="name-name">团队能力</div>
          <div class="name-name-eng">Team competence</div>
        </div>
        <div class="list">
          <div v-for="(item, index) in teamList" :key="index" class="list-item">
            <div>
              <img
                :src="require(`@/assets/images/aboutMine/team${index + 1}.png`)"
                alt=""
              />
            </div>
            <div class="team-title">
              <div class="title">{{ item.title }}</div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 康米斯资质 -->
    <div class="qualifications">
      <div class="qualifications-content">
        <div class="name-title" style="padding: 96px 0 64px 0">
          <div class="name-name">康米斯资质</div>
          <div class="name-name-eng">Comis Qualification</div>
        </div>
        <img src="@/assets/images/aboutMine/qualifications.png" alt="" />
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="contact">
      <div class="contact-content">
        <div class="name-title" style="padding: 96px 0 64px 0">
          <div class="name-name">联系我们</div>
          <div class="name-name-eng">Contact us</div>
        </div>
        <div class="map-box">
          <div
            class="map"
            id="baiduMap"
            style="width: 720px; height: 420px"
          ></div>
          <div class="map-content">
            <div class="tel" style="margin-top: 61px">
              <img src="@/assets/images/aboutMine/tel.png" alt="" />
              <div class="tel-box">
                <div class="phone">联系电话：</div>
                <div class="num">400-669-0980</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="tel">
              <img src="@/assets/images/aboutMine/tel.png" alt="" />
              <div class="tel-box">
                <div class="phone">地址：</div>
                <div class="num">
                  <span>浙江省</span><span>杭州市</span> <span>滨江区</span>
                  <span>智慧E谷</span>A座5楼
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="tel">
              <img src="@/assets/images/aboutMine/tel.png" alt="" />
              <div class="tel-box">
                <div class="phone">南京办事处：</div>
                <div class="num">
                  <span>江宁区</span> <span>艺术家园</span>2-3-143
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 首页 -->
    <template>
      <bottom></bottom>
    </template>
  </div>
</template>
<script type="text/javascript" src="https://api.map.baidu.com/api?v=1.0&type=webgl&ak=hPgPnPKhvyP1mFcZ37jqtvapT60cQpw7"></script>
<script>
import bottom from "./bottom.vue";
import heade from "./header.vue";
export default {
  components: { heade, bottom },
  data() {
    return {
      bannerHeight: 0,
      teamList: [
        {
          title: "研发能力",
          text: "研发团队大厂从业经验＞10年",
        },
        {
          title: "市场能力",
          text: "核心团队平均行业经验＞10年",
        },
        {
          title: "服务能力",
          text: "售前+售中+售后全流程快速响应能力",
        },
        {
          title: "创新能力",
          text: "自主研发多项发明专利及实用新型专利",
        },
        {
          title: "定制能力",
          text: "从需求到解决方案，量身定制",
        },
        {
          title: "行业前三",
          text: "部分细分领域市场占有率",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.imgLoag();
    window.addEventListener(
      "resize",
      () => {
        this.imgLoag();
      },
      false
    );
    this.$nextTick(function () {
      var _this = this;
      _this.MP().then((BMap) => {
        var map = new BMap.Map("baiduMap", { enableMapClick: false });
        var point = new BMap.Point(120.229434, 30.214279);
        map.centerAndZoom(point, 20);
        map.enableScrollWheelZoom(true);
      });
    });
  },
  methods: {
    MP() {
      let ak = "hPgPnPKhvyP1mFcZ37jqtvapT60cQpw7";
      return new Promise(function (resolve, reject) {
       if (typeof BMap !== 'undefined') {
            resolve(BMap)
            return true
        }
        window.onBMapCallback = function () {
            resolve(BMap)
        }
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://api.map.baidu.com/api?v=3.0&ak=' + ak + '&callback=onBMapCallback'
        script.onerror = reject
        document.head.appendChild(script)
      });
    },
    imgLoag() {
      this.$nextTick(() => {
        const page = document.getElementById("page");
        const pageWidth = page.offsetWidth;
        this.bannerHeight = (pageWidth * 560) / 1920;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page-bg {
  width: 100vw;
  position: relative;
  img {
    width: 100vw;
  }
}
.page-text {
  position: absolute;
  top: 42%;
  left: 19.1%;
  color: #fefefe;
  .china {
    font-weight: 300;
    font-size: 40px;
  }
  .english {
    font-weight: 300;
    font-size: 24px;
    margin-top: 3px;
  }
}
.name--title {
  padding: 100px 0 48px 0;
}
.name-name {
  font-size: 32px;
  color: #2c2c2c;
  text-align: center;
}
.name-name-eng {
  font-weight: 300;
  font-size: 18px;
  color: #707070;
  text-align: center;
}
.company {
  width: 100vw;
  height: 582px;
  background: #fbfbfb;
  .company-content {
    min-width: 1200px;
    width: 1200px;
    margin: 0 auto;
  }
  .text {
    font-weight: 300;
    font-size: 24px;
    color: #2c2c2c;
    line-height: 40px;
    text-indent: 2em;
  }
}
.education {
  width: 100vw;
  height: 628px;
  .education-content {
    min-width: 1200px;
    width: 1200px;
    margin: 0 auto;
  }
  .education-text {
    display: flex;
    justify-content: space-between;
  }
  .mission {
    width: 378px;
    height: 269px;
    background: #fbfbfb;
    text-align: center;
    .title {
      font-weight: bold;
      font-size: 40px;
      color: #42578b;
      margin: 80px 0 24px 0;
    }
    .desc {
      font-size: 24px;
      color: #2c2c2c;
      letter-spacing: 16px;
    }
  }
  .price {
    width: 378px;
    height: 269px;
    background: #fbfbfb;
    text-align: center;
    .title {
      font-weight: bold;
      font-size: 40px;
      color: #5e9b52;
      margin: 80px 0 24px 0;
    }
    .desc {
      font-size: 24px;
      color: #2c2c2c;
      span {
        margin-right: 24px;
      }
      span:last-child {
        margin-right: 0px;
      }
    }
  }
  .future {
    width: 378px;
    height: 269px;
    background: #fbfbfb;
    text-align: center;
    .title {
      font-weight: bold;
      font-size: 40px;
      color: #3268af;
      margin: 80px 0 24px 0;
    }
    .desc {
      font-size: 24px;
      color: #2c2c2c;
      span {
        margin-right: 24px;
      }
      span:last-child {
        margin-right: 0px;
      }
    }
  }
}

.team {
  width: 100vw;
  height: 565px;
  background: #fbfbfb;

  .team-content {
    min-width: 1200px;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 24px;
    background-color: #fbfbfb;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .list-item {
      margin-bottom: 72px;
      width: 400px;
      display: flex;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
      }
      .team-title {
        margin-left: 24px;
        .title {
          font-size: 24px;
          color: #2c2c2c;
        }
        .text {
          font-size: 16px;
          color: #2c2c2c;
          margin-top: 10px;
        }
      }
    }
  }
}
.qualifications {
  width: 100vw;
  height: 565px;
  background-color: #ffffff;
  .qualifications-content {
    min-width: 1200px;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 24px;

    img {
      width: 1200px;
      height: 292px;
    }
  }
}
.contact {
  width: 100vw;
  background-color: #ffffff;
  margin-bottom: 200px;
  .contact-content {
    min-width: 1200px;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 24px;

    .map {
      width: 720px;
      height: 420px;
      // background-color: pink;
    }
    .map-box {
      display: flex;
      .map-content {
        margin-left: 64px;
        .line {
          width: 336px;
          height: 1px;
          background: #dbdbdb;
          margin-bottom: 32px;
        }
        .tel {
          display: flex;
          padding-bottom: 32px;
          img {
            width: 40px;
            height: 51px;
          }
          .tel-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 16px;
          }
          .phone {
            font-size: 18px;
            color: #2c2c2c;
          }
          .num {
            font-size: 18px;
            color: #2c2c2c;
          }
          span {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
