var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",attrs:{"id":"page"}},[_c('heade',{attrs:{"active":6}}),_c('div',{staticStyle:{"height":"64px"}}),_c('div',{staticClass:"page-bg",style:({ height: _vm.bannerHeight + 'px' })},[_c('img',{style:({ height: _vm.bannerHeight + 'px' }),attrs:{"src":require("../assets/images/aboutMine/bg.png"),"alt":""}}),_vm._m(0)]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"team"},[_c('div',{staticClass:"team-content"},[_vm._m(3),_c('div',{staticClass:"list"},_vm._l((_vm.teamList),function(item,index){return _c('div',{key:index,staticClass:"list-item"},[_c('div',[_c('img',{attrs:{"src":require(`@/assets/images/aboutMine/team${index + 1}.png`),"alt":""}})]),_c('div',{staticClass:"team-title"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.text))])])])}),0)])]),_vm._m(4),_vm._m(5),[_c('bottom')]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-text"},[_c('div',{staticClass:"china"},[_vm._v("关于康米斯")]),_c('div',{staticClass:"english"},[_vm._v("About Comis")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company"},[_c('div',{staticClass:"company-content"},[_c('div',{staticClass:"name-title",staticStyle:{"padding":"96px 0 48px 0"}},[_c('div',{staticClass:"name-name"},[_vm._v("公司简介")]),_c('div',{staticClass:"name-name-eng"},[_vm._v("Company Profile")])]),_c('div',{staticClass:"text"},[_vm._v(" 浙江康米斯信息技术有限公司是一家以IOT设备连接和云服务平台为基础，围绕物联网、车联网等多种行业场景，依托云计算、大数据、人工智能、物联网等信息技术，提供北斗+物联网位置服务的科技型企业。 ")]),_c('div',{staticClass:"text"},[_vm._v(" 公司提供“北斗智能设备+云平台+行业数字化解决方案”等多种产品组合，为用户多元需求场景提供差异化的解决方案。 ")]),_c('div',{staticClass:"text"},[_vm._v(" 自主研发的核心产品“智位星物联”云平台及大数据应用，创新融合实现基于北斗+物联网位置服务的多场景需求。助力企业降本增效，提高行业竞争优势，更好的服务行业用户，赋能行业发展。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"education"},[_c('div',{staticClass:"education-content"},[_c('div',{staticClass:"name-title",staticStyle:{"padding":"106px 0 48px 0"}},[_c('div',{staticClass:"name-name"},[_vm._v("企业文化")]),_c('div',{staticClass:"name-name-eng"},[_vm._v("corporate culture")])]),_c('div',{staticClass:"education-text"},[_c('div',{staticClass:"mission"},[_c('div',{staticClass:"title"},[_vm._v("使命")]),_c('div',{staticClass:"desc"},[_vm._v("让位置更有价值")])]),_c('div',{staticClass:"price"},[_c('div',{staticClass:"title"},[_vm._v("价值观")]),_c('div',{staticClass:"desc"},[_c('span',[_vm._v("诚信")]),_c('span',[_vm._v("开放")]),_c('span',[_vm._v("双赢")])])]),_c('div',{staticClass:"future"},[_c('div',{staticClass:"title"},[_vm._v("愿景")]),_c('div',{staticClass:"desc"},[_c('span',[_vm._v("专注位置服务")]),_c('span',[_vm._v("赋能行业发展")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name-title",staticStyle:{"padding":"96px 0 64px 0"}},[_c('div',{staticClass:"name-name"},[_vm._v("团队能力")]),_c('div',{staticClass:"name-name-eng"},[_vm._v("Team competence")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qualifications"},[_c('div',{staticClass:"qualifications-content"},[_c('div',{staticClass:"name-title",staticStyle:{"padding":"96px 0 64px 0"}},[_c('div',{staticClass:"name-name"},[_vm._v("康米斯资质")]),_c('div',{staticClass:"name-name-eng"},[_vm._v("Comis Qualification")])]),_c('img',{attrs:{"src":require("@/assets/images/aboutMine/qualifications.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('div',{staticClass:"contact-content"},[_c('div',{staticClass:"name-title",staticStyle:{"padding":"96px 0 64px 0"}},[_c('div',{staticClass:"name-name"},[_vm._v("联系我们")]),_c('div',{staticClass:"name-name-eng"},[_vm._v("Contact us")])]),_c('div',{staticClass:"map-box"},[_c('div',{staticClass:"map",staticStyle:{"width":"720px","height":"420px"},attrs:{"id":"baiduMap"}}),_c('div',{staticClass:"map-content"},[_c('div',{staticClass:"tel",staticStyle:{"margin-top":"61px"}},[_c('img',{attrs:{"src":require("@/assets/images/aboutMine/tel.png"),"alt":""}}),_c('div',{staticClass:"tel-box"},[_c('div',{staticClass:"phone"},[_vm._v("联系电话：")]),_c('div',{staticClass:"num"},[_vm._v("400-669-0980")])])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"tel"},[_c('img',{attrs:{"src":require("@/assets/images/aboutMine/tel.png"),"alt":""}}),_c('div',{staticClass:"tel-box"},[_c('div',{staticClass:"phone"},[_vm._v("地址：")]),_c('div',{staticClass:"num"},[_c('span',[_vm._v("浙江省")]),_c('span',[_vm._v("杭州市")]),_vm._v(" "),_c('span',[_vm._v("滨江区")]),_c('span',[_vm._v("智慧E谷")]),_vm._v("A座5楼 ")])])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"tel"},[_c('img',{attrs:{"src":require("@/assets/images/aboutMine/tel.png"),"alt":""}}),_c('div',{staticClass:"tel-box"},[_c('div',{staticClass:"phone"},[_vm._v("南京办事处：")]),_c('div',{staticClass:"num"},[_c('span',[_vm._v("江宁区")]),_vm._v(" "),_c('span',[_vm._v("艺术家园")]),_vm._v("2-3-143 ")])])])])])])])
}]

export { render, staticRenderFns }